export function animation() {

  $(window).scroll(function (){
    $('.fadein-up').each(function(){
        var position = $(this).offset().top;
        var scroll = $(window).scrollTop();
        var windowHeight = $(window).height();
        if (scroll > position - windowHeight + 150){
          $(this).addClass('active');
        }
    });
    $('.fadein-left').each(function(){
        var position = $(this).offset().top;
        var scroll = $(window).scrollTop();
        var windowHeight = $(window).height();
        if (scroll > position - windowHeight + 150){
          $(this).addClass('active');
        }
    });
    $('.fadein-right').each(function(){
        var position = $(this).offset().top;
        var scroll = $(window).scrollTop();
        var windowHeight = $(window).height();
        if (scroll > position - windowHeight + 150){
          $(this).addClass('active');
        }
    });
    $('.fadein-down').each(function(){
        var position = $(this).offset().top;
        var scroll = $(window).scrollTop();
        var windowHeight = $(window).height();
        if (scroll > position - windowHeight + 150){
          $(this).addClass('active');
        }
    });
    $('.fadein').each(function(){
        var position = $(this).offset().top;
        var scroll = $(window).scrollTop();
        var windowHeight = $(window).height();
        if (scroll > position - windowHeight + 300){
          $(this).addClass('active');
        }
    });
  });
}
