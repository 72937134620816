export function header() {
  //burger button
  $(function(){
    $('.j-header_btn').on('click', function(){
        $('.l-header').toggleClass('active');
    });
    $('.j-header_link').on('click', function(){
      $('.l-header').toggleClass('active');
    });
  });

  //modal menu
  $( function(){
    // モーダル領域をクリックでフェードアウトさせる
    $( '.l-header_nav_sp' ).click( function(){
      $('.l-header').toggleClass('active');
    } );
    // がしかし、画像をクリックでキャンセルさせる
    $( '.l-header_nav_sp_item' ).on( 'click', function( e ){
      e.stopPropagation();
    } );
  } );

  //スティッキーヘッダー
  $(function(){
    var $win = $(window),
        $fv = $('.l-fv'),
        $header = $('.l-header'),
        fvHeight = $fv.outerHeight(),
        fixedClass = 'fixed';

    $win.on('load scroll',function(){
        var value = $(this).scrollTop();
        if ($win.width() > 768 || $win.width() < 768){
            if(value > fvHeight){
                $header.addClass(fixedClass);
            }else{
                $header.removeClass(fixedClass);
            }
        }
    });
  });
}
